import Carousel from 'react-bootstrap/Carousel';
import {Event, TOP_BANNER} from "../../../Helper";

function showBannerMapesa() {
    Event.emit('show_banner_mapesa');
}

function showBannerCrasher() {
    //Event.emit('show_banner_crasher');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showBannerMapesa}>
                <img className={"d-block w-100"} style={{borderRadius: '6px 6px 0px 0px'}} src={TOP_BANNER} alt="Mapesa"/>
                <div className={'d-block w-100 bg-image-custom'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}>Highest Crashers: ...</div>
            </Carousel.Item>
            <Carousel.Item onClick={showBannerCrasher}>
                <img style={{borderRadius: '6px 6px 0px 0px'}} className="d-block w-100" src={TOP_BANNER} alt="Crasher"/>
                <div className={'d-block w-100 bg-image-custom'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}>Daily: ...</div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;